<template>
  <b-container class="px-1">
    <icon v-if="appState=='loading'" name="spinner" pulse scale="4"/> 
      <div v-else-if="appState=='error'">Fehler beim Lesen der Daten.</div> 
      <div v-else-if="appState=='ready'"> 

    <b-table responsive striped :items="playerRows" :fields="fieldsComputed">
      <template v-for="field in fieldsComputedEditable" v-slot:[cellname(field)]="row">
                <div :key="field">
                <!-- <div :key="field" :style="{width:'20px'}"> -->
                  <input :type="(field=='is_active' || field=='send_update_mail') ? 'checkbox' : 'text'" :readonly="field=='_id'"
                  :style="columnWidth(field) ? {width:''+columnWidth(field)+'px'} : {}"
                  v-model="row.item[field]" 
                  @focus="$event.target.select()"
                  :data-test-id="'input-' + field + '-' + row.item.user_id"
                  />
                </div>
      </template> 
    </b-table>
    <b-button @click="addPlayer()" data-test-id="btn-add-player">+</b-button>
    <b-button @click="getData()">Reset</b-button>
    <b-button @click="savePlayers()" data-test-id="btn-save">Save</b-button>
    <b-button @click="testOut">Test out</b-button>

  <b-container>
    <b-row class="my-1">
      <b-col sm="4"><b-button @click="setAllEmail">set all email</b-button></b-col>
      <b-col sm="4">
        <input type="checkbox" v-model="allEmailInput"/>
      </b-col>
    </b-row>
        
  </b-container>
  <b-table responsive striped :items="linkRows" :fields="linkFields">
    
  </b-table>
  </div>
 </b-container>
 </template>

<script>
import Utils,{ makeCopy } from '../helper/Utils'
import ModelUtils from '../helper/ModelUtils'
import DBAPI from '../db/dbapi'


export default {
  name: 'Admin',
  mounted() {
    
    this.processParams()
    this.processQuery()
    this.getData()
  },
  watch: {
    	'$route': function(newRoute, oldRoute) {
        this.processParams()
        this.processQuery()
      },
    },
  data () {
    return {
      appState:'loading',
      players: [],
      clubNr:"",
      linkFields:["fname", "lname", "ttoodleLink"],
      allEmailInput:false,
    }
  },
  computed: {
    playerRows() {
      return this.players;
    },
    fieldsComputed() {
      return this.playerProps()
    },
    fieldsComputedEditable() {
      var fields = this.fieldsComputed

      if (!fields) {
        return []
      }

      return fields
    },
    linkRows() {
      let players = this.players.map(p => {
        return {fname:p.fname, lname:p.lname, ttoodleLink:"https://ttoodle.heinrich-ms.de/#/land/u?userId=" + p.user_id};
      });

      return players;
    }
  },
  methods: {
    headname(name) { return 'head(' + name + ')'},
    cellname(name) { return 'cell(' + name + ')'},
    footname(name) { return 'foot(' + name + ')'},
    playerProps() {
      var players = this.players;
      if (!players || !players.length) return []
      // console.log(players)
      // var keys = Object.keys(players[0])
      
      return Utils.objProps(players[0])
    },
    columnWidth(colName) {
      var small = 28;
      var middle = 2.4 * small;
      var large = 2.4 * middle;
      var nameWidth = 100;
      var widths = {
        "user_id": middle,
        "club_nr": middle,
        "team_id": middle,
        "team_nr": middle,
        "team_nr_preferred": middle,
        "pos": small,
        "pos_preferred": small,
        "fname": nameWidth,
        "lname": nameWidth,
        // "email": "lothar-heinrich@gmx.de",
        "send_update_mail": small,
        "is_active": small
      }   
      if (widths[colName]) {
        return widths[colName]
      }
      return null
    },
    getData: function() {
      var self = this
      var userData = this.$$$userData()
      let userId = userData.user_id
      if (!userId) {
        console.error("nicht angemeldet!")
        self.appState = 'error'
        return
      }

      if (userData.permission_group < "300") {
        // club admin is minimum
        console.error("Du hast nicht die benötigten Rechte")
        self.appState = 'error'
        return
      }

      var params = {$$$model:"Player", club_nr:this.clubNr} 
      this.appState = 'loading'
      DBAPI.getFind(params).then(function(response) {
          if (response.data.error){
            //app.errorMessage = response.data.message;
            console.error(response.data)
            self.appState = 'error'

					}	else {
            // console.log(response.data)
            self.players = response.data.sort((a, b) => {//FIXME
              if (a.team_nr < b.team_nr) return -100;
              if (a.team_nr > b.team_nr) return 100;
              if (a.pos < b.pos) return -1;
              if (a.pos > b.pos) return 1;

              return 0
            })
            self.appState = 'ready'
                    
          }       
      })
      .catch(e => {
        console.error(e)
        self.appState = 'error'
      }); 
    },
    savePlayers: function() {

      var updatedPlayers = this.players.filter(p => p._id !== "AUTO")
      var addedPlayers = this.players.filter(p => p._id === "AUTO")
      addedPlayers = addedPlayers.map(p => {delete p["_id"]; delete p["__v"];return p})

      var data = updatedPlayers.concat(addedPlayers)
      
      // data.forEach(d => {if (d.personId.trim() === "") d.personId=null})
      
      // https://github.com/axios/axios/issues/827
      var self = this
      // console.log(JSON.stringify(addedPlayers, undefined, 3))
      // return
      DBAPI.putUpsert({model:'Player', keyNames:['_id'], items:data}).then(function(response) {
          if (response.data.error) {
            //app.errorMessage = response.data.message;
            console.error("error DBAPI.putUpsert:")
            console.error(response.data)
					}	else {
          }       
      }).catch(e => console.log("DBAPI.putUpsert catch: ", e));

    },
    addPlayer() {
      if (!this.players.length) return
      var newPlayer = makeCopy(this.players[this.players.length-1])
      newPlayer._id = "AUTO"
      let playerArr = this.players
      newPlayer.user_id = 1 + Math.max.apply(Math, (playerArr.map(function(o) { return Number(o.user_id); })))                          
      newPlayer.pos = newPlayer.pos + 1
      newPlayer.pos_preferred = newPlayer.pos_preferred + 1
      // newPlayer.team_nr = 0
      // newPlayer.team_nr_preferred = 0
      // newPlayer.send_update_mail = false
      // newPlayer.is_active = true
      // newPlayer.club_nr = ""
      newPlayer.personId = null
      newPlayer.fname = ""
      newPlayer.lname = ""
      newPlayer.email = ""
      newPlayer.permissions = ""
      newPlayer.permission_group = ""
      
      this.players.push(newPlayer)
    },
    goBack: function() {
      this.$router.go(-1);
    },
    processParams() {
      
      if (this.$route.params) {
        this.clubNr = this.$route.params.clubNr
      } 
    },
    
    processQuery() {
      // console.log("$route.query", JSON.stringify(this.$route.query))
      if (this.$route.query) {
        
      }
    },
    setAllEmail() {
      console.log('allEmailInput', this.allEmailInput);
      if (this.allEmailInput != '0' && this.allEmailInput != '1') {
        console.error("nur 1 oder 0 erlaubt")
        return
      }
      var allEmailInput = this.allEmailInput
      this.players.forEach(p => {
        p.send_update_mail = allEmailInput
      })
    },
    onInput(row, field, value) {
      console.log(row, field, value)
    },
    testOut() {
      console.log(this.players)
    }
  }
}  
   
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scope>
/* ol li {
  background-color: #333;
  border-radius: 4px;
  color: #fff;
  margin-bottom: 2px;
  padding: 6px 6px;
} */

/* ol li span {
  color: #999;
  float: right;
} */
ol li.scale-enter-active, ol li.scale-move, ol li.scale-leave-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}
ol li.scale-enter {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
ol li.scale-leave-active {
  opacity: 0;
  position: absolute;
  -webkit-transform: scale(0);
  transform: scale(0);
}
</style>
